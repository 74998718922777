import Domain from '@/types/Domain';
import { CampaignVerification } from '@/types/CampaignVerification';
import CampaignCustomerField from '@/domains/campaign/CampaignCustomerField';
import CampaignCodeProperties from '@/domains/campaign/CampaignCodeProperties';
import CodeType from '@/types/CodeType';
import CampaignArticle from './CampaignArticle';
import CampaignAdditionalInfo from '@/domains/campaign/CampaignAdditionalInfo';
import displayDate from '@/utils/displayDate';
import SalesCampaign from '@/domains/campaign/SalesCampaign';
import CampaignAccessProperties from '@/domains/campaign/CampaignAccessProperties';

interface Props {
  campaignType: 'CAMPAIGN_DEFAULT' | 'CAMPAIGN_SALES' | 'CAMPAIGN_ACCESS';
  accessProperties: CampaignAccessProperties;
  id: string;
  active: boolean;
  name: string;
  codeProperties: CampaignCodeProperties;
  verification: CampaignVerification;
  tokenDistributionType: 'DOWNLOAD' | 'ON_DEMAND';
  mandantId: string;
  fileUploadActive: boolean;
  startDate: Date;
  endDate: Date;
  landingPageUrl: string;
  costCenter: string;
  campaignArticles: CampaignArticle[];
  customerFields: CampaignCustomerField[];
  customerFieldsEnabled?: boolean;
  approvalMail?: string;
  repeatMail?: string;
  mandantName?: string;
  fileUploadHint?: string;
  verificationInstruction?: string;
  verificationDocuments?: File;
  additionalInfo?: CampaignAdditionalInfo;
  groups: string[];
  externalId?: string;
  salesProperties?: SalesCampaign;
  authDataCount?: number;
}

class Campaign extends Domain implements Props {
  public static createAll(campaigns: any[]): Campaign[] {
    return campaigns.map(Campaign.create);
  }

  public static create(data: any): Campaign {
    const mergedCodeProps: CampaignCodeProperties = {
      ...data.codeProperties,
      quantityUpdated: data.newCodesGeneratedAt,
    };

    return new Campaign(
      data.campaignType,
      CampaignAccessProperties.create(data.accessProperties || {}),
      data.id,
      typeof data.active === 'boolean'
        ? (data.active as boolean)
        : !!parseInt(data.active),
      data.name,
      CampaignCodeProperties.create(mergedCodeProps || {}),
      data.verification,
      data.tokenDistributionType,
      data.mandantId,
      data.fileUploadActive || false,
      data.startDate && new Date(data.startDate),
      data.endDate && new Date(data.endDate),
      data.landingPageUrl,
      data.costCenter,
      // TODO: wrap this with the creator after refactoring CampaignArticle
      data.campaignArticles,
      !!data.customerFields?.length || false,
      CampaignCustomerField.createAll(data.customerFields || []),
      data.approvalMail,
      data.repeatMail,
      data.mandantName,
      data.fileUploadHint,
      data.verificationInstruction,
      data.verificationDocuments,
      CampaignAdditionalInfo.create(data.additionalInfo || {}),
      data.groups,
      data.externalId,
      SalesCampaign.create(data.salesProperties || {}),
      data.authDataCount
    );
  }

  private constructor(
    public campaignType:
      | 'CAMPAIGN_DEFAULT'
      | 'CAMPAIGN_SALES'
      | 'CAMPAIGN_ACCESS',
    public accessProperties: CampaignAccessProperties,
    public id: string,
    public active: boolean,
    public name: string,
    public codeProperties: CampaignCodeProperties,
    public verification: CampaignVerification,
    public tokenDistributionType: 'DOWNLOAD' | 'ON_DEMAND' = 'DOWNLOAD',
    public mandantId: string,
    public fileUploadActive: boolean,
    public startDate: Date,
    public endDate: Date,
    public landingPageUrl: string,
    public costCenter: string,
    public campaignArticles: CampaignArticle[],
    public customerFieldsEnabled: boolean,
    public customerFields: CampaignCustomerField[],
    public approvalMail?: string,
    public repeatMail?: string,
    public mandantName?: string,
    public fileUploadHint?: string,
    public verificationInstruction?: string,
    public verificationDocuments?: File,
    public additionalInfo?: CampaignAdditionalInfo,
    public groups: string[] = [],
    public externalId?: string,
    public salesProperties?: SalesCampaign,
    public authDataCount?: number
  ) {
    super();
  }

  get marketingEndDateMin(): Date {
    // cloning the startDate
    let startDate = new Date(this.startDate);
    if (typeof this.startDate === 'string') {
      startDate = new Date(startDate);
    }
    startDate = new Date(Number(startDate));
    startDate.setDate(startDate.getDate() + 1);
    return startDate;
  }

  get startDateMin(): Date {
    const startDate = new Date();
    startDate.setDate(startDate.getDate());
    startDate.setHours(0);
    return startDate;
  }

  get endDateMin(): Date {
    let startDate;
    if (this.startDate === null) {
      startDate = new Date();
    } else {
      startDate = new Date(this.startDate);
      startDate = this.assureDateType(startDate);
      startDate.setDate(startDate.getDate() + 1);
      startDate.setHours(0);
    }
    return startDate;
  }

  get endDateMax(): Date {
    let startDate;
    if (this.startDate === null) {
      startDate = new Date();
    } else {
      startDate = new Date(this.startDate);
      startDate = this.assureDateType(startDate);
      startDate.setMonth(startDate.getMonth() + 6);
      startDate.setHours(0);
    }
    return startDate;
  }

  get marketingEndDateMax(): Date {
    let endDate = new Date(this.endDate);
    endDate = this.assureDateType(endDate);
    endDate = new Date(Number(endDate));
    endDate.setDate(endDate.getDate() - 30);
    return endDate;
  }

  get marketingEndDateMinFormatted(): string {
    return displayDate(this.marketingEndDateMin);
  }

  get marketingEndDateMaxFormatted(): string {
    return displayDate(this.marketingEndDateMax);
  }

  get redemptionDelayMax(): number {
    let endDate = new Date(this.endDate);
    let x = 30;
    endDate = this.assureDateType(endDate);
    let lowerBoundaryDate = this.startDate;
    if (this.salesProperties.marketingEndDate) {
      lowerBoundaryDate = this.salesProperties.marketingEndDate;
    }
    lowerBoundaryDate = this.assureDateType(lowerBoundaryDate);

    x =
      (endDate.getTime() - lowerBoundaryDate.getTime()) / (1000 * 3600 * 24) -
      22;

    if (x > 30) {
      x = 30;
    }

    return x;
  }

  get redemptionDelayMaxFormatted(): string {
    const x = this.redemptionDelayMax;
    if (x > 1) {
      return '' + x + ' Tage';
    } else {
      return '1 Tag';
    }
  }

  public constraintsCustomerFields(): {} {
    const applyConstraintsFromArray = (arr: Domain[], path: string) => {
      const newConstraints = {};
      arr.forEach((el: Domain, index: number) => {
        const constraints = el.constraints();
        Object.keys(constraints).forEach(
          (key: string) =>
            (newConstraints[`${path}.${index}.${key}`] = constraints[key])
        );
      });
      return newConstraints;
    };
    return applyConstraintsFromArray(this.customerFields, 'customerFields');
  }

  public constraints(): {} {
    return {
      'verificationDocuments.size': {
        numericality: {
          greaterThan: 0,
          lessThanOrEqualTo: 10489000,
          message: '^Datei darf höchstens 10MB groß sein.',
        },
      },
      fileUploadHint: {
        length: {
          minimum: 3,
          maximum: 1000,
          tooShort: '^Muss mindestens 3 Zeichen lang sein',
          tooLong: '^Darf maximal 1000 Zeichen lang sein',
        },
      },
      verificationInstruction: {
        length: {
          minimum: 3,
          maximum: 1000,
          tooShort: '^Muss mindestens 3 Zeichen lang sein',
          tooLong: '^Darf maximal 1000 Zeichen lang sein',
        },
      },
      description: {
        length: {
          minimum: 3,
          message: '^Muss mindestens 3 Zeichen enthalten.',
        },
      },
      approvalMail: {
        email: {
          message: '^Muss eine valide Email Adresse sein.',
        },
      },
    };
  }

  public clone(): Campaign {
    const clone = JSON.parse(JSON.stringify(this));
    const recreated = Campaign.create({
      ...clone,
      verificationDocuments: this.verificationDocuments,
    });
    recreated.accessProperties.image = this.accessProperties.image;
    return recreated;
  }

  public toFormData(): FormData {
    const {
      campaignType,
      accessProperties,
      startDate,
      endDate,
      landingPageUrl,
      approvalMail,
      costCenter,
      codeProperties,
      customerFields,
      verification,
      verificationInstruction,
      verificationDocuments,
      campaignArticles,
      fileUploadActive,
      fileUploadHint,
      additionalInfo,
      groups,
      externalId,
      salesProperties,
      customerFieldsEnabled,
    } = this;

    const data = new FormData();

    Object.getOwnPropertyNames(this).forEach((key) => {
      const excludedProperties = [
        'errors',
        'campaignType',
        'accessProperties',
        'id',
        '__ob__',
        'campaignArticles',
        'verificationInstruction',
        'verificationDocuments',
        'customerFields',
        'mandantId',
        'mandantName',
        'approvalMail',
        'repeatMail',
        'startDate',
        'endDate',
        'landingPageUrl',
        'toFormData',
        'codeProperties',
        'costCenter',
        'fileUploadActive',
        'fileUploadHint',
        'additionalInfo',
        'groups',
        'externalId',
        'salesProperties',
        'active',
        'customerFieldsEnabled',
        'authDataCount',
      ];

      if (excludedProperties.indexOf(key) === -1) {
        data.append(key, (this as any)[key]);
      }
    });

    data.append('campaignType', campaignType);

    data.append(`startDate`, new Date(startDate).toISOString());
    data.append(`endDate`, new Date(endDate).toISOString());

    data.append('codeProperties[type]', codeProperties.type.id);

    if (campaignType === 'CAMPAIGN_ACCESS') {
      data.append('accessProperties[image]', accessProperties.image as Blob);
      if (accessProperties.displayName) {
        data.append(
          'accessProperties[displayName]',
          accessProperties.displayName
        );
      }

      if (accessProperties.description) {
        data.append(
          'accessProperties[description]',
          accessProperties.description
        );
      }

      data.append(
        'accessProperties[customErrorMessage]',
        accessProperties.customErrorMessage
      );

      accessProperties.columnInformations?.forEach(
        (customerField: any, index) => {
          data.append(
            `accessProperties[columnInformations][${index}][name]`,
            customerField.name
          );
          data.append(
            `accessProperties[columnInformations][${index}][description]`,
            customerField.description
          );
          data.append(
            `accessProperties[columnInformations][${index}][regExp]`,
            customerField.regExp
          );
          data.append(
            `accessProperties[columnInformations][${index}][caseSensitive]`,
            customerField.caseSensitive
          );
        }
      );
    }

    if (
      codeProperties.type === CodeType.SINGLE_USAGE ||
      codeProperties.type === CodeType.GENERIC_LIMITED
    ) {
      if (campaignType !== 'CAMPAIGN_ACCESS') {
        data.append(
          'codeProperties[quantity]',
          codeProperties.quantity?.toString()
        );
      }
    }

    if (verification === 'APPROVAL_BY_USER') {
      if (approvalMail) {
        data.append('approvalMail', approvalMail);
      }
    }

    if (fileUploadActive) {
      data.append('fileUploadActive', fileUploadActive.toString());
      data.append('fileUploadHint', fileUploadHint);

      if (verificationDocuments) {
        data.append('verificationDocuments[0]', verificationDocuments as Blob);
      }
      if (verificationInstruction) {
        data.append('verificationInstruction', verificationInstruction);
      }
    }

    if (additionalInfo.isActive) {
      data.append(
        'additionalInfo[additionalInfoText]',
        additionalInfo.additionalInfoText
      );
      data.append(
        'additionalInfo[additionalInfoConfirm]',
        additionalInfo.additionalInfoConfirm.toString()
      );
    }

    if (landingPageUrl) {
      data.append(`landingPageUrl`, landingPageUrl);
    }

    if (costCenter) {
      data.append(`costCenter`, costCenter);
    }

    if (externalId) {
      data.append(`externalId`, externalId);
    }

    if (campaignType === 'CAMPAIGN_SALES') {
      if (groups) {
        groups.forEach((id: string, index) => {
          data.append(`groups[${index}]`, id);
        });
      }
      if (salesProperties.redemptionDelay) {
        data.append(
          'salesProperties[redemptionDelay]',
          salesProperties.redemptionDelay.toString()
        );
      }
      data.append('salesProperties[description]', salesProperties.description);

      if (salesProperties.customMessagingEnabled !== false) {
        data.append(
          'salesProperties[messagingProperties][email][senderName]',
          salesProperties.messagingProperties.email.senderName
        );
        data.append(
          'salesProperties[messagingProperties][email][subject]',
          salesProperties.messagingProperties.email.subject
        );
        data.append(
          'salesProperties[messagingProperties][email][text]',
          salesProperties.messagingProperties.email.text.templateText
        );

        data.append(
          'salesProperties[messagingProperties][sms][senderName]',
          salesProperties.messagingProperties.sms.senderName
        );
        data.append(
          'salesProperties[messagingProperties][sms][text]',
          salesProperties.messagingProperties.sms.text.templateText
        );
      }
    }

    if (salesProperties.marketingEndDate) {
      // TODO: Do we have to test for type here or is marketingEndDate always a string at this point?
      if (typeof salesProperties.marketingEndDate === 'string') {
        data.append(
          `salesProperties[marketingEndDate]`,
          salesProperties.marketingEndDate
        );
      } else {
        data.append(
          `salesProperties[marketingEndDate]`,
          salesProperties.marketingEndDate.toISOString()
        );
      }
    }

    if (customerFieldsEnabled) {
      customerFields.forEach((customerField: CampaignCustomerField, index) => {
        data.append(`customerFields[${index}][name]`, customerField.name);
        data.append(
          `customerFields[${index}][description]`,
          customerField.description
        );
        data.append(`customerFields[${index}][regExp]`, customerField.regExp);
        data.append(`customerFields[${index}][type]`, customerField.type);
        data.append(
          `customerFields[${index}][required]`,
          customerField.required ? '1' : '0'
        );
      });
    }

    if (campaignArticles) {
      campaignArticles.forEach((campaignArticle: CampaignArticle, index) => {
        // @ts-ignore
        const idString = campaignArticle.article.id;
        const positionString = (index + 1).toString();
        const paymentAmountString =
          campaignArticle.coPaymentGrossC2B.amount.toString();
        const paymentCurrencyString =
          campaignArticle.coPaymentGrossC2B.currency.toString();

        data.append(`campaignArticles[${index}][articleId]`, idString);
        data.append(
          `campaignArticles[${index}][articlePosition]`,
          positionString
        );
        data.append(
          `campaignArticles[${index}][coPaymentGrossC2B][amount]`,
          paymentAmountString
        );
        data.append(
          `campaignArticles[${index}][coPaymentGrossC2B][currency]`,
          paymentCurrencyString
        );
      });
    }

    return data;
  }

  private assureDateType(date): Date {
    if (typeof date === 'string') {
      return new Date(date);
    } else {
      return date;
    }
  }
}

export default Campaign;
